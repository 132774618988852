/* ************************************************************************************************ */
/* Tables ***************************************************************************************** */
/* ************************************************************************************************ */
#root {
	table {
	    &.table {
	        width: 100%;
	        max-width: 100%;
	        border-collapse: collapse;
		    border: 0 none;
		    background-color: transparent;

		    @include rounded(15px);
		    @include boxshadow(0 0 2px rgba(#e1e8f4,1.00));

		    /* CPQI Colors */
		    .cpqi-score-block {
			    display: block;

			    span {
				    display: inline-block;

				    &.cpqi-score-value {
					    padding-right: 8px;
				        }
				    &.cpqi-score-color {
					    width: 12px;
					    height: 12px;

					    @include rounded(2px);
				        }
			    }
		    }

	        tr {}
	        td {}
	        th {}

	        thead {
	            tr {}
	            td {}

	            th,td {
	                padding: 1.45rem;
		            color: #647590;
		            font-family: $font4;
		            font-weight: 700;
	                text-align: inherit;
		            text-transform: uppercase;
	                vertical-align: middle;
	                border-top-width: 0;
	                border-right: 1px solid #e1e8f4;
	                border-bottom: 1px solid #e1e8f4;
		            background-color: $color-white;

		            &:first-child {

			            @include rounded(15px 0 0);
	                    }
		            &:last-child {
			            border-right-width: 0;

			            @include rounded(0 15px 0 0);
		                }
	            }

	            &.thead-light {}
	            &.thead-dark {}
	        }

	        tbody {


	            tr {
		            background-color: transparent;

		            th,td {
		                padding: 1.25rem 1.45rem;
			            color: #647590;
		                text-align: inherit;
		                vertical-align: top;
		                border-top: 1px solid #e1e8f4;
		                border-right: 1px solid #e1e8f4;

			            @include boxshadow(none);

			            &:first-child {}
			            &:last-child {
				            border-right-width: 0;
			                }

			            &.utility { text-transform: uppercase; }
	                }

		            button {
			            &.btn-utility {
				            margin: 0;
				            padding: 0;
				            display: block;
				            width: auto;
				            max-width: 100%;
				            color: #647590;
				            text-transform: uppercase;
				            border-width: 0;
				            background-color: transparent;

				            @include transition(color 0.10s ease-in-out);

				            /* Hover State */
				            &:hover {
					            color: $color-orange-1;
				                }
			            }
		            }

		            &:last-child {
			            @include rounded(0 0 15px 15px);

			            th,td {
				            &:first-child {
				                @include rounded(0 0 0 15px);
			                    }
				            &:last-child {
				                @include rounded(0 0 15px);
			                    }
	                    }
		            }
	            }
	        }

	        /* Table Striped */
	        &.table-striped {
	            tbody {
	                tr {
	                    &:nth-of-type(2n+1){
		                    th,td {
			                    color: #647590;
			                    background: #f8f9fc;
		                        }
                        }
	                    &:nth-of-type(2n){
		                    th,td {
			                    color: #647590;
			                    background: $color-white;
		                        }
                        }
	                }

	                th {}
	                td {}
	            }
	        }

	        /* Table Bordered */
	        &.table-bordered {
	            border: 1px solid #e1e8f4;
	            }
	    }
	}
}