/* ************************************************************************************************ */
/* Data Grid ************************************************************************************** */
/* ************************************************************************************************ */
#root {
	.grid-data-block {
		display: inline-block;

		.grid-data-value {
			margin-right: 8px;
			display: inline-block;
			}

		.grid-data-color {
			display: inline-block;
			width: 12px;
			height: 12px;

			@include rounded(2px);
			}
	}

	/* Count Information */
	.showing-count-information {
		> p {
			&.MuiTypography-root {
				font-family: $font3;
				}
		}
	}

	.MuiBox-root {

		/* Pagination */
		.MuiPagination-root {
			&.MuiPagination-text {}

			ul {
				&.MuiPagination-ul {
					> li {
						button {
							&.MuiButtonBase-root {
								&.MuiPaginationItem-root {
									font-family: $font3;
									}
								&.MuiPaginationItem-sizeMedium {}
								&.MuiPaginationItem-rounded {}
								&.Mui-disabled {}
								&.Mui-selected {
									background-color: #e1e8f4;
									}
								&.MuiPaginationItem-previousNext {
									background-color: $color-white;
									}
							}
						}
					}
				}
			}
		}
	}

	/* Top Grid Search */
	.search-field {
		.MuiInputBase-root {
			background-color: #e2e8f4;

			@include boxshadow(1px 1px 4px rgba($color-black,0.04) inset);
		}

		input {
			&.MuiInputBase-input {
				padding: 13px 16px;
				color: #647590;
				font-size: 0.95rem;
				background-color: transparent;

		        &::-webkit-input-placeholder {
		            color: #647590;
		            }
		        &::-moz-placeholder {
		            color: #647590;
		            }
		        &:-ms-input-placeholder {
		            color: #647590;
		            }
		        &:-moz-placeholder {
		            color: #647590;
		            }
			}
		}

		.MuiInputAdornment-root {
			svg {}
		}
		fieldset {
			&.MuiOutlinedInput-notchedOutline {
				border-width: 0;
			}
		}
	}

	/* Top Grid Order By */
	.order-by {
		align-items: center;

		.order-by-inner {
			padding-left: 16px;
			background-color: #F2F5FA;

			@include boxshadow(1px 1px 4px rgba($color-black,0.04) inset);
			@include rounded(4px);
			}

		span {
			color: #647590;
			font-family: $font3;
			text-transform: uppercase;
			}

		.MuiInputBase-root {
			font-size: 0.95rem !important;

			.MuiSelect-select {
				padding: 13px 32px 13px 14px;
				color: #2a4879;
				font-family: $font3;
				font-weight: bold;
				font-weight: 600;
				}
			fieldset {
				&.MuiOutlinedInput-notchedOutline {
					border-width: 0;
					}
			}
		}
	}

	/* Top Grid Show Limiter */
	.show-limiter {
		align-items: center;
		gap: 16px 0 16px 16px;

		.show-limiter-inner {
			padding-left: 16px;
			background-color: #F2F5FA;

			@include boxshadow(1px 1px 4px rgba($color-black,0.04) inset);
			@include rounded(4px);
			}

		span {
			color: #647590;
			font-family: $font3;
			text-transform: uppercase;
			}

		.MuiInputBase-root {
			font-size: 0.95rem !important;

			.MuiSelect-select {
				padding: 13px 32px 13px 14px;
				color: #2a4879;
				font-weight: bold;
				font-weight: 600;
				}
			fieldset {
				&.MuiOutlinedInput-notchedOutline {
					border-width: 0;
				}
			}
		}
	}

	/* Data Grid */
	.MuiDataGrid-root {
		@include rounded(8px);

		&.MuiDataGrid-autoHeight {}
		&.MuiDataGrid-root--densityStandard {}

		.MuiDataGrid-main {

			/* Headers */
			.MuiDataGrid-columnHeaders {
				color: #647590;
	            font-family: $font4;
				font-size: 0.90rem;
	            font-weight: 700;
	            text-align: inherit;
	            text-transform: uppercase;
				background-color: $color-white;

				@include rounded(8px 8px 0 0);

				.MuiDataGrid-columnHeadersInner {
					div[class*="MuiDataGrid-columnHeaderRow"] {
						.MuiDataGrid-columnHeader {
							padding-right: 24px;
							padding-left: 24px;
							height: 70px;
							border-left: 1px solid #e1e8f4;

							&:first-child { border-left-width: 0; }

							&.MuiDataGrid-columnHeader--sortable {}

							.MuiDataGrid-columnHeaderDraggableContainer {
								.MuiDataGrid-columnHeaderTitleContainer {
									.MuiDataGrid-columnHeaderTitleContainerContent {
										.MuiDataGrid-columnHeaderTitle {
											font-family: $font4;
											line-height: 1.2;

											/* Remove Ellipsis Overflow */
											text-overflow: unset;
											white-space: break-spaces;
											}
									}
								}
							}

							.MuiDataGrid-columnSeparator {
								&.MuiDataGrid-columnSeparator--sideRight {
									display: none;

									right: 0;
									width: 1px;
									background-color: #e1e8f4;

									svg { display: none; }
								}
							}
						}
					}
				}
			}

			/* Rows */
			.MuiDataGrid-virtualScroller {
				.MuiDataGrid-virtualScrollerContent {
					.MuiDataGrid-virtualScrollerRenderZone {
						.MuiDataGrid-row {
							color: #647590;
							font-size: 0.95rem;

							.MuiDataGrid-cell {
								padding-right: 24px;
								padding-left: 24px;
								font-family: $font4;
								border-left: 1px solid #e1e8f4;

								&:first-child {
									border-left-width: 0;
									}

								&.MuiDataGrid-cell--withRenderer {}
								&.MuiDataGrid-cell--textLeft {}

								/* Grid Column White Space: Column 1 */
								&:nth-child(1) {
									p {
										&.MuiTypography-root {
											white-space: break-spaces;
											}
									}
								}
								
								/* Grid Column Alignment: Columns 3, 4, 5, 6 */
								&:nth-child(3),
								&:nth-child(4),
								&:nth-child(5),
								&:nth-child(6) {
									text-align: right !important;

									.MuiDataGrid-cellContent {
										width: 100%;
										text-align: right !important;
										}
								}

								/* Grid Column Alignment: Column 2 */
								&:nth-child(2) {
									text-align: center !important;
									
									.grid-data-block {
										margin: 0 auto;
										}
								}
							}

		                    &:nth-of-type(2n+1){
			                    .MuiDataGrid-cell {
				                    background: #f8f9fc;
		                            }
	                        }

		                    &:nth-of-type(2n){
			                    .MuiDataGrid-cell {
				                    background: $color-white;
		                            }
	                        }

							&.MuiDataGrid-row--lastVisible {
								.MuiDataGrid-cell {
									&:first-child {
										@include rounded(0 0 0 8px);
										}
									&:last-child {
										@include rounded(0 0 8px);
										}
								}
							}
						}
					}
				}
			}
		}
	}

	.MuiDataGrid-columnHeader {
		padding-right: 24px;
		padding-left: 24px;
		height: 70px;
		border-left: 1px solid #e1e8f4;

		&:first-child {
			border-left-width: 0;
			}
	}

	.MuiDataGrid-columnSeparator {
		&.MuiDataGrid-columnSeparator--sideRight {
			display: none;

			right: 0;
			width: 1px;
			background-color: #e1e8f4;

			svg { display: none; }
		}
	}

	.MuiDataGrid-columnHeaderTitle {
		font-family: $font4;
		line-height: 1.2;

		/* Remove Ellipsis Overflow */
		text-overflow: unset;
		white-space: break-spaces;
		}
}


.MuiPaper-root {
	table {
		&.MuiTable-root {
			thead {
				tr {
					th,td {
						padding: 24px;
						font-size: 0.80rem;
					}
				}
			}
			tbody {
				tr {
					th,td {
						padding: 24px;
						}
					p {
						padding-top: 0;
						padding-bottom: 0;
						}
				}
			}
		}
	}
}

.national-average-row td {
	color: #2A4879
}

/* National Average/Sticky */
.MuiPaper-root {
	table {
		&.MuiTable-root {
			tbody {
				&.MuiTableBody-root {
					tr {
						&.national-average-row {
							&.sticky-row {
								position: sticky;
								top: 0;
								z-index: 9999;
								border-bottom: 1px solid #d7d7d7 !important;
								background-color: #f5f5f5;

								th, td {
									&.MuiTableCell-root {
										position: relative;
										overflow: visible;
										font-weight: bold;

										&::before {
											position: absolute;
											bottom: 0;
											left: 0;
											display: block;
											width: 100%;
											height: 1px;
											content: ' ';
											background-color: #e0e0e0;
											}
									}
								}

								td {
									&.MuiTableCell-root {
										&::after {
											position: absolute;
											top: 0;
											left: -1px;
											display: block;
											width: 1px;
											height: 100%;
											content: ' ';
											background-color: #e0e0e0;
											}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.MuiBox-root .MuiPagination-root ul.MuiPagination-ul>li button{
	padding: 12px 16px;
	color: #656F7C;
	border-radius: 4px;
	font-size: 14px;
	width: 38px;
	height: 40px;
}

.MuiBox-root .MuiPagination-root ul.MuiPagination-ul>li button.MuiButtonBase-root.Mui-selected {
	background-color: #F2F5FA;
}

.MuiBox-root .MuiPagination-root ul.MuiPagination-ul>li button.MuiButtonBase-root.MuiPaginationItem-previousNext {
	border: 1px solid #E1E8F4;
	border-radius: 4px;
	color: #647590;
	border-color: #E1E8F4;
}

.MuiTableRow-root:nth-of-type(odd) {
	background-color: #F8F9FC !important;
}