/* ************************************************************************************************ */
/* Colors ***************************************************************************************** */
/* ************************************************************************************************ */
$color-white:       #fff;
$color-black:       #000;

$color-blue-1:      #f2f5fa;
$color-blue-2:      #1f70ab;
$color-blue-3:      #1f71ab;
$color-blue-4:      #647590;
$color-blue-5:      #2a4879;
$color-blue-6:      #e2e8f4;
$color-blue-7:      #F0F4F9;

$color-gray-f:      #fafafa;
$color-gray-e:      #e5e5e5;
$color-gray-d:      #ddd;
$color-gray-c:      #ccc;
$color-gray-b:      #bbb;
$color-gray-a:      #aaa;
$color-gray-9:      #999;
$color-gray-8:      #888;
$color-gray-7:      #777;
$color-gray-6:      #666;
$color-gray-5:      #555;
$color-gray-4:      #444;
$color-gray-3:      #333;
$color-gray-2:      #222;
$color-gray-1:      #111;

$color-orange-1:    #f0c415;

$color-red-1:       #c70808;

$color-logo:        #403F41;
$color-text:        #647590;



/* Text Colors */
.txt-white      { color: $color-white !important; }
.txt-black      { color: $color-black !important; }

.txt-blue-1 	{ color: $color-blue-1 !important; }
.txt-blue-2 	{ color: $color-blue-2 !important; }
.txt-blue-3 	{ color: $color-blue-3 !important; }
.txt-blue-4 	{ color: $color-blue-4 !important; }
.txt-blue-5 	{ color: $color-blue-5 !important; }
.txt-blue-6 	{ color: $color-blue-6 !important; }

.txt-gray-f 	{ color: $color-gray-f !important; }
.txt-gray-e 	{ color: $color-gray-e !important; }
.txt-gray-d 	{ color: $color-gray-d !important; }
.txt-gray-c 	{ color: $color-gray-c !important; }
.txt-gray-b 	{ color: $color-gray-b !important; }
.txt-gray-a 	{ color: $color-gray-a !important; }
.txt-gray-9 	{ color: $color-gray-9 !important; }
.txt-gray-8 	{ color: $color-gray-8 !important; }
.txt-gray-7 	{ color: $color-gray-7 !important; }
.txt-gray-6 	{ color: $color-gray-6 !important; }
.txt-gray-5 	{ color: $color-gray-5 !important; }
.txt-gray-4 	{ color: $color-gray-4 !important; }
.txt-gray-3 	{ color: $color-gray-3 !important; }
.txt-gray-2 	{ color: $color-gray-2 !important; }
.txt-gray-1 	{ color: $color-gray-1 !important; }

.txt-orange-1 	{ color: $color-orange-1 !important; }

.txt-red-1 		{ color: $color-red-1 !important; }


/* Background Colors */
.bg-white       { background-color: $color-white !important; }
.bg-black       { background-color: $color-black !important; }

.bg-blue-1 		{ background-color: $color-blue-1 !important; }
.bg-blue-2 		{ background-color: $color-blue-2 !important; }
.bg-blue-3 		{ background-color: $color-blue-3 !important; }
.bg-blue-4 		{ background-color: $color-blue-4 !important; }
.bg-blue-5 		{ background-color: $color-blue-5 !important; }
.bg-blue-6 		{ background-color: $color-blue-6 !important; }

.bg-gray-f 		{ background-color: $color-gray-f !important; }
.bg-gray-e 		{ background-color: $color-gray-e !important; }
.bg-gray-d 		{ background-color: $color-gray-d !important; }
.bg-gray-c 		{ background-color: $color-gray-c !important; }
.bg-gray-b 		{ background-color: $color-gray-b !important; }
.bg-gray-a 		{ background-color: $color-gray-a !important; }
.bg-gray-9 		{ background-color: $color-gray-9 !important; }
.bg-gray-8 		{ background-color: $color-gray-8 !important; }
.bg-gray-7 		{ background-color: $color-gray-7 !important; }
.bg-gray-6 		{ background-color: $color-gray-6 !important; }
.bg-gray-5 		{ background-color: $color-gray-5 !important; }
.bg-gray-4 	    { background-color: $color-gray-4 !important; }
.bg-gray-3 	    { background-color: $color-gray-3 !important; }
.bg-gray-2 	    { background-color: $color-gray-2 !important; }
.bg-gray-1 	    { background-color: $color-gray-1 !important; }

.bg-orange-1 	{ background-color: $color-orange-1 !important; }

.bg-red-1 		{ background-color: $color-red-1 !important; }