/* ************************************************************************************************ */
/* Import Fonts *********************************************************************************** */
/* ************************************************************************************************ */

/* Rubik: Light (weight 200) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-Light.ttf?v=1.0.0') format('truetype');
	font-weight: 200;
	font-style: normal;
}

/* Rubik: Light Italic (weight 200) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-LightItalic.ttf?v=1.0.0') format('truetype');
	font-weight: 200;
	font-style: italic;
}

/* Rubik: Regular (weight normal) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-Regular.ttf?v=1.0.0') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* Rubik: Regular (weight 400) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-Regular.ttf?v=1.0.0') format('truetype');
	font-weight: 400;
	font-style: normal;
}

/* Rubik: Regular Italic (weight normal) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-Italic.ttf?v=1.0.0') format('truetype');
	font-weight: normal;
	font-style: italic;
}

/* Rubik: Regular Italic (weight 400) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-Italic.ttf?v=1.0.0') format('truetype');
	font-weight: 400;
	font-style: italic;
}

/* Rubik: Medium (weight 600) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-Medium.ttf?v=1.0.0') format('truetype');
	font-weight: 600;
	font-style: normal;
}

/* Rubik: Medium Italic (weight 600) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-MediumItalic.ttf?v=1.0.0') format('truetype');
	font-weight: 600;
	font-style: italic;
}

/* Rubik: Bold (weight 700) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-Bold.ttf?v=1.0.0') format('truetype');
	font-weight: 700;
	font-style: normal;
}

/* Rubik: Bold Italic (weight 700) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-BoldItalic.ttf?v=1.0.0') format('truetype');
	font-weight: 700;
	font-style: italic;
}

/* Rubik: Black (weight 900) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-Black.ttf?v=1.0.0') format('truetype');
	font-weight: 900;
	font-style: normal;
}

/* Rubik: Black Italic (weight 900) */
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik/Rubik-BlackItalic.ttf?v=1.0.0') format('truetype');
	font-weight: 900;
	font-style: italic;
}



/* Montserrat: Thin */
@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

/* Montserrat: Thin Italic */
@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

/* Montserrat: Extra Light */
@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

/* Montserrat: Extra Light Italic */
@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

/* Montserrat: Light */
@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

/* Montserrat: Light Italic */
@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

/* Montserrat: Regular */
@font-face {
	font-family: 'Montserrat';
	src:  url('../fonts/montserrat/Montserrat-Regular.eot?hu3sw6');
	src:  url('../fonts/montserrat/Montserrat-Regular.ttf?hu3sw6') format('truetype'),
		  url('../fonts/montserrat/Montserrat-Regular.woff?hu3sw6') format('woff'),
		  url('../fonts/montserrat/Montserrat-Regular.woff2?hu3sw6') format('woff');
	font-weight: normal;
	font-weight: 400;
	font-style: normal;
}

/* Montserrat: Italic */
@font-face {
	font-family: 'Montserrat';
	src:  url('../fonts/montserrat/Montserrat-Italic.eot?hu3sw6');
	src:  url('../fonts/montserrat/Montserrat-Italic.ttf?hu3sw6') format('truetype'),
		  url('../fonts/montserrat/Montserrat-Italic.woff?hu3sw6') format('woff'),
		  url('../fonts/montserrat/Montserrat-Italic.woff2?hu3sw6') format('woff');
	font-style: italic;
}

/* Montserrat: Medium */
@font-face {
	font-family: 'Montserrat';
	src:  url('../fonts/montserrat/Montserrat-Medium.eot?hu3sw6');
	src:  url('../fonts/montserrat/Montserrat-Medium.ttf?hu3sw6') format('truetype'),
		  url('../fonts/montserrat/Montserrat-Medium.woff?hu3sw6') format('woff'),
		  url('../fonts/montserrat/Montserrat-Medium.woff2?hu3sw6') format('woff');
	font-weight: 500;
	font-style: normal;
}

/* Montserrat: Medium Italic */
@font-face {
	font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

/* Montserrat: Semi Bold */
@font-face {
	font-family: 'Montserrat';
	src:  url('../fonts/montserrat/Montserrat-SemiBold.eot?hu3sw6');
	src:  url('../fonts/montserrat/Montserrat-SemiBold.ttf?hu3sw6') format('truetype'),
		  url('../fonts/montserrat/Montserrat-SemiBold.woff?hu3sw6') format('woff'),
		  url('../fonts/montserrat/Montserrat-SemiBold.woff2?hu3sw6') format('woff');
	font-weight: 600;
	font-style: normal;
}

/* Montserrat: Semi Bold Italic */
@font-face {
	font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

/* Montserrat: Bold */
@font-face {
	font-family: 'Montserrat';
	src:  url('../fonts/montserrat/Montserrat-Bold.eot?hu3sw6');
	src:  url('../fonts/montserrat/Montserrat-Bold.ttf?hu3sw6') format('truetype'),
		  url('../fonts/montserrat/Montserrat-Bold.woff?hu3sw6') format('woff'),
		  url('../fonts/montserrat/Montserrat-Bold.woff2?hu3sw6') format('woff');
	font-weight: 700;
	font-style: normal;
}

/* Montserrat: Bold Italic */
@font-face {
	font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

/* Montserrat: Extra Bold */
@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

/* Montserrat: Extra Bold Italic */
@font-face {
	font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

/* Montserrat: Black */
@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserrat/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

/* Montserrat: Extra Black Italic */
@font-face {
	font-family: 'Montserrat';
    src: url('../fonts/montserrat/Montserrat-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}



/* ************************************************************************************************ */
/* System Text Fonts ****************************************************************************** */
/* ************************************************************************************************ */
.font {
    font-family: Arial, Helvetica, sans-serif;
    
    &-arial 			{ font-family: Arial, Helvetica, sans-serif; }
	&-times				{ font-family: TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia, serif; }
    &-rubik 		    { font-family: 'Rubik', Arial, Helvetica, sans-serif; }
}
