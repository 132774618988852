/* ************************************************************************************************ */
/* Loaders **************************************************************************************** */
/* ************************************************************************************************ */

/* Loading Spinner */
.vs-ajax-loading {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 99999;
	margin: -30px 0 0 -30px;
	display: block;
	width: 80px;
	height: 80px;
	background-color: $color-white;
	background-color: rgba($color-white,0.80);
	
	@include rounded(4px);
	@include boxshadow(0 0 30px rgba($color-blue-1,0.15));
	
	.vs-ajax-loading-outer {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		margin: -25px 0 0 -25px;
		width: 50px;
		height: 50px;
		border: 8px solid $color-blue-3;
		
		@include rounded(50%);
		
		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			margin: -25px 0 0 -25px;
			width: 50px;
			height: 50px;
			content: ' ';
			border-color: $color-blue-1 transparent transparent;
			border-style: solid;
			border-width: 8px;
			
			@include rounded(50%);
			-webkit-animation:  spin 1s linear infinite;
			-moz-animation:     spin 1s linear infinite;
			-ms-animation:      spin 1s linear infinite;
			-o-animation:       spin 1s linear infinite;
			animation:          spin 1s linear infinite;
			}
	}
	.vs-ajax-loading-inner {}
}


/* Preloader */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9901;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    visibility: visible;
    background-color: $color-white;
    
    @include opacity(1.00);
    @include transition(all .35s ease-out);
    
    &.spinner-preloader {
        
        .preloader-wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 9902;
            margin: -25px 0 0 -25px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background-position: center center;
            background-repeat: no-repeat;
            
            @include animation(spin 1s infinite);
            
            &::before {
                position: relative;
                z-index: 1;
                display: block;
                width: 100%;
                height: 100%;
                content: ' ';
                border: 10px solid $color-blue-2;
                border-color: rgba($color-blue-2,0.20);
                
                @include rounded(50%);
                }
            &::before {
                position: relative;
                z-index: 2;
                display: block;
                width: 100%;
                height: 100%;
                content: ' ';
                border-top: 10px solid $color-blue-3;
                
                @include rounded(50%);
                }
        }
        
        &.preloader-removed {
            visibility: hidden;
            
            @include opacity(0.00);
            }
    }
}



/* Tooltip */
.copy-share-tooltip {
	position: absolute;
	top: 100%;
	left: 50%;
	margin: 10px 0 0 -55px;
	display: none;
	width: 110px;
	color: $color-blue-2;
	font-size: 0.65rem;
	text-align: center;
	border: 1px solid $color-gray-4;
	background-color: $color-gray-1;
	
	@include rounded(2px);
	@include userselect(none);
	}