/* ************************************************************************************************ */
/* Bootstrap Info Modal *************************************************************************** */
/* ************************************************************************************************ */
.modal-backdrop {
	background-color: $color-blue-3;

	&.fade {}
}

.modal {
	&.ting-modal {
		.modal-dialog {
			max-width: 825px;

			.modal-content {
				border-width: 0;
				background-color: $color-blue-1;
				background: linear-gradient(0deg, $color-blue-1, $color-blue-1), $color-white;

				@include rounded(24px);

				/* Header */
				.modal-header {
					padding-right: 28px;
					padding-left: 28px;
					border-width: 0;

					.modal-title {
						color: $color-blue-5;
						font-family: $font4;
						font-weight: bold;
						font-weight: 700;
						text-transform: uppercase;

						br {
							display: block;
							}

						span {
							display: block;
							color: $color-blue-4;
							font-family: $font3;
							font-size: 1.00rem;
							font-weight: normal;
							font-weight: 400;
							}
					}

					button {
						&.btn-close {
							margin-right: 5px;
							background-color: $color-blue-6;

							@include rounded(8px);
							}
					}
				}

				/* Modal Body */
				.modal-body {
					padding-top: 0;

					.modal-body-inner {
						padding: 32px;
						background-color: $color-white;

						@include rounded(12px);

						h1,h2,h3,h4,h5,h6,p,li {
							color: $color-blue-4;
							}

						:first-child { padding-top: 0; }
						:last-child { padding-bottom: 0; }

						h3 {
							padding: 15px 0 10px;
							font-weight: bold;
							font-weight: 700;
							text-transform: uppercase;
							}

						p {
						    padding: 10px 0;
						    font-size: 1.05rem;
						    line-height: 1.55rem;
							}

						li {
						    padding: 8px 0;
						    font-size: 1.05rem;
						    line-height: 1.55rem;
							}

						ul {
							&.about-info-list {
								li {
									position: relative;
									padding-left: 35px;

									svg {
										position: absolute;
										top: 8px;
										left: 3px;
										display: block;
										width: 22px;
										color: $color-orange-1;
										}

									&.location-marker {
										svg {
											top: 1px;
											}
									}
								}
							}
						}
					}
				}

				/* Modal Footer */
				.modal-footer {
					display: none;
					border-width: 0;

					button {
						&.btn {

							}
					}
				}
			}
		}
	}
}

/* Modal Chart */
.chart-frame {
	position: relative;

	.chart-label-title {
		position: absolute;
		z-index: 1;
		color: #2a4879;
		font-family: $font3;
		font-size: 0.95rem;
		font-weight: bold;
		font-weight: 600;
		line-height: 1;

		&.x-axis {
			bottom: -15px;
			width: 100%;
			color: #555;
			text-align: center;
			}
		
		&.y-axis {
			width: 200px;
			text-align: center;
			display: block;
			
			@include transform( rotate(-90deg) translateY(-105px) translateX(-170px) );
		}
	}
}