/* ************************************************************************************************ */
/* Header ***************************************************************************************** */
/* ************************************************************************************************ */
#root {

	.title-container {
		position: relative;
		padding-left: 24px;
		margin-left: 24px;

		&:before {
			content: '';
			position: absolute;
			height: 100%;
			width: 1px;
			left: 0;
			top: 0;
			background-color: rgba(0,0,0,0.12);
		}
	}

	.stat-thd {
		.title-container {
			color: white;

			p {
				color: rgba(255,255,255,.7);
			}
		}

		.header-top-right {
			min-width: 360px;
		}
	}

	/* Header Position: Top */
	.header-top {
		position: relative;
		padding: 20px 0 35px;
	
		&:after {
			display: none;
		}

		.home-button {
			border-radius: 50%;
			height: 52px;
			width: 52px;
			margin-right: 24px;

			.icon {
				position: relative;
				top: -2px;
				width: 24px;
				height: 24px;
			}
		}

		h1 {
			font-size: 24px;
			font-weight: 600;
			font-family: "Montserrat", sans-serif;
			line-height: 1.5em;
		}

		p {
			font-size: 16px;
			font-weight: normal;
			font-family: "Rubik", sans-serif;
			color: #647590;
		}
	}

	/* Header Position: Bottom */
	.header-bottom {
		/*
		position: relative;
		position: sticky;
		top: 0;
		z-index: 1500;
		*/
		padding: 10px 0 25px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;

		&:after,
		&:before {
			display: none;
		}
	}

	/* Logo */
	.header-logo {
		img {
			display: block;
			float: left;
			width: 75px;
			}

		
		color: $color-logo;

		span {
			// padding: 20px 0 0 25px;
			display: block;
			float: left;
			font-family: $font4;
			font-size: 1.25rem;

			& > small {
				font-size: 11px;
				font-weight: 700;
				line-height: 2em;
			}
		}
	}

	.header-top-right {
		position: relative;
		min-width: 420px;
	}

	/* Button: About CPQI Score */
	.btn-about-cpqi-score {
		// position: absolute;
		top: 22px;
		right: 200px;
		z-index: 2;
		padding-left: 36px;
		color: $color-text;
		font-size: 1.00rem;
		text-transform: uppercase;
		vertical-align: middle;

		svg {
			// position: absolute;
			height: 1em;
			color: $color-orange-1;
			vertical-align: middle;
			margin-right: .5em;
		}

		/* Hover State */
		&:hover {
			color: $color-orange-1;
			}
	}

	/* Social Share */
	ul {
		&.header-social-list {
			position: relative;
			right: 0;
			z-index: 2;
			margin: 0;
			padding: 0;
			display: block;
			list-style-type: none;

			margin-left: 8px;
			padding-left: 24px;

			&::before {
				position: absolute;
				top: 50%;
				left: 0;
				margin-top: -12px;
				width: 1px;
				height: 24px;
				content: ' ';
				background-color: rgba($color-white, 0.20);
			}

			> li {
				display: block;
				float: left;

				&.social-share-label {
					position: relative;
					padding-right: 16px;
					color: $color-text;
					font-size: 1.00rem;
					line-height: 24px;
					text-transform: uppercase;
				}

				&.social-share-link {
					a {
						display: block;
						width: 24px;
						height: 24px;

						@include transition(opacity 0.10s ease-in-out);

						/* Hover State */
						&:hover {
							@include opacity(0.60);
							}
					}

					&.facebook {
						padding-right: 8px;

						a {
							background: url("../images/icon-facebook.svg") center center no-repeat;
							}
					}
					&.linkedin {
						a {
							background: url("../images/icon-linkedin.svg") center center no-repeat;
						}
					}
					&.twitter {
						padding-right: 8px;

						a {
							background: url("../images/icon-twitter.svg") center center no-repeat;
						}
					}
				}
			}
		}
	}

	/* Mobile Social Share */
	.dropdown {
		&.header-mobile-social-share {
			// position: absolute;
			// top: 22px;
			right: 0;
			z-index: 2;
			display: none;

			button {
				&.dropdown-toggle {
					margin: 0;
					font-family: $font3;
					text-transform: uppercase;
					border-color: #01383f;
					background-color: #01383f;

					@include transition(
						color 0.10s ease-in-out,
						border-color 0.10s ease-in-out,
						background-color 0.10s ease-in-out
					);

					&::after {}

					svg {
						width: 15px;
						height: 15px;
						}

					&:hover {
						border-color: #f0c415;
						background-color: #f0c415;
						}
				}
			}
		}
	}

	/* Navigation */
	.primary-nav,
	.type-toggle-nav {
		display: block;
		float: left;

		ul {
			margin: 0;
			padding: 3px;
			display: block;
			float: left;
			list-style-type: none;

			@include rounded(8px);

			li {
				margin: 0;
				padding: 0 3px;
				display: block;
				float: left;
				font-size: 0.90rem;
				line-height: 1;
				text-transform: uppercase;

				&:first-child { padding-left: 0; }
				&:last-child { padding-right: 0; }

				a {
					padding: 15px;
					display: block;
					color: #647590;

					@include rounded(5px);
					@include transition(background-color 0.35s ease-in-out);

					/* Active State */
					&.active {
						background-color: $color-white;
						color: $color-blue-5;
						}
				}

				&:hover {
					a {
						background-color: $color-white;
						}
				}
			}
		}
	}

	.primary-nav ul {
		background-color: $color-blue-7;
	}

	.type-toggle-nav ul {
		background-color: $color-blue-1;
	}

	/* Toggle */
	.type-toggle-nav {
		position: sticky;
		margin-left: 20px;

		&.utility-region {}

		&.month-average {
			float: right;
			}
	}
}

/* Media Queries */
/* 1170px */
@media screen and (max-width: 1170px){
	#root {
		.header-design {
			.header-design-outer {
				height: 900px;
				}
		}

		.header-bottom {
			.primary-nav {
				margin-right: 20px;
				margin-bottom: 10px;
			}

			.type-toggle-nav {
				&.utility-region {
					margin-left: 0;
					clear: right;
					margin-bottom: 10px;
					// float: none;

					&::after {
						clear: both;
						display: table;
						content: ' ';
					}
				}

				&.month-average {
					// margin-top: 10px;
					margin-left: 0;
					float: none;
				}
			}
		}
	}
}

/* 1025px */
@media screen and (max-width: 1025px){
	#root {

		.header-design {
			.header-design-bar {
				// right: -130px;
				left: auto;

				@include transform(translateX(0));
				}
		}

		.btn-about-cpqi-score {
			right: 70px;
		}

		ul {
			&.header-social-list {
				// display: none;
			}
		}

		.dropdown {
			&.header-mobile-social-share {
				// display: block;
			}
		}
	}
}

/* 900px */
@media screen and (max-width: 900px){
	#root {
		.header-logo {
			span {
				font-size: 1.00rem;
				}
		}
	}
}