/* ************************************************************************************************ */
/* Animations ************************************************************************************* */
/* ************************************************************************************************ */
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}