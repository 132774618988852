/* ************************************************************************************************ */
/* Legend ***************************************************************************************** */
/* ************************************************************************************************ */
.map-legend {
	position: relative;
	width: 200px;
	background-color: #003940;
	background-color: rgba(#003940, 0.60);

	@include rounded(10px);

	.legend-title {
		padding: 24px 24px 0;
		color: white;
		font-family: 'Montserrat', sans-serif;
		font-weight: 600;
		text-align: center;
	}

	.map-legend-inner {
		padding: 24px 15px 24px 62px;
		overflow: hidden;
		position: relative;
	}

	.legend-range-container {
		position: absolute;
		width: 150%;
		list-style-type: none;
		left: 1.5rem;
		top: 50%;
		@include transform(translate(-50%, 0%));
	}

	ul {
		&.legend-range {
			@include transform(rotate(-90deg));

			li {
				color: $color-white;
				text-transform: uppercase;

				&.legend-range-label {
					&.worse {
						float: left;
						}
					&.better {
						float: right;
						}
				}

				&.legend-range-arrow {
					position: absolute;
					top: 7px;
					left: 50%;
					margin: 0;
					width: 55%;
					height: 1px;
					background-color: $color-white;
					/* background-color: rgba($color-white,0.40); */

					@include transform(translateX(-50%));
					@include opacity(0.40);

					&::before {}

					&::after {}

					.legend-chevron {
						position: absolute;
						top: -6px;
						width: 13px;
						height: 13px;

						&.chevron-right { right: -4px; }
						&.chevron-left { left: -4px; }
					}
				}
			}
		}

		&.legend-values-list {
			margin: 0;
			padding: 0;
			list-style-type: none;

			li {
				position: relative;
				padding: 7px 0 7px 30px;
				display: block;
				color: $color-white;
				font-size: 0.90rem;

				span {
					&.legend-value-numbers {
						font-feature-settings: 'tnum' on, 'lnum' on;
						}
					&.legend-value-color {
						position: absolute;
						top: 50%;
						left: 0;
						display: block;
						width: 20px;
						height: 20px;
						border-style: solid;
						border-width: 2px;

						@include rounded(3px);
						@include transform(translateY(-50%));
						}
				}
			}
		}
	}
}

/* Modal Legend */
.modal {
	&.ting-modal {

		a {
			text-decoration: underline !important;

			/* Hover State */
			&:hover {
				color: #f0c415 !important;
				}
		}

		.modal-dialog {
			.modal-content {
				.map-legend {
					position: relative;
					right: auto;
					bottom: auto;
					margin-right: 0;
					padding-top: 12px !important;
					padding-bottom: 12px !important;
					float: right;
					background-color: #f2f5fa;

					.map-legend-inner {
						/* position: relative; */
						padding: 24px 15px 24px 62px;
						}

					ul {
						&.legend-range {
							top: 22px;
							left: 72px;

							li {
								&.legend-range-label {
									padding: 0 !important;
									}
								&.legend-range-arrow {
									margin-top: 5px;
									padding: 0 !important;
									background-color: #647590;
									}
							}
						}

						&.legend-values-list {
							li {
								padding: 3px 0 3px 30px !important;
								color: #647590;
								font-size: 0.90rem;
								}
						}
					}

				}
			}
		}
	}
}

/* Map Legend */
.data-map {
	.map-legend {
		position: absolute;
		right: 24px;
		bottom: 24px;
		z-index: 5;
		}
}