/* ************************************************************************************************ */
/* Newsletter ************************************************************************************* */
/* ************************************************************************************************ */
.newsletter {
	position: relative;
	padding: 40px 40px 40px 145px;
	height: 100%;
	background-color: $color-white;
	border: 1px solid #E1E8F4;

	@include rounded(16px);

	svg {
		position: absolute;
		top: 46px;
		left: 69px;
		display: block;
		width: 38px;
		color: $color-orange-1;
		}

	.form-group {
		position: relative;
		padding-right: 100px;

		button {
			&.btn-signup {
				position: absolute;
				top: 0;
				right: 0;
				z-index: 1;
				margin: 0;
				padding: 14px 16px;

				@include rounded(8px);
				}
		}
	}
}