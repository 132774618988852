/* ************************************************************************************************ */
/* Header Background Design *********************************************************************** */
/* ************************************************************************************************ */
.header-design {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;

	/* Header Bar */
	.header-design-bar {
		position: absolute;
		top: 0;
		left: 50%;
		width: 100%;
		max-width: 1600px;

		@include transform(translateX(-50%));

		@media screen and (min-width: 768px) and (any-pointer: fine) {
			&::before {
				position: absolute;
				top: 0;
				left: -3000px;
				display: block;
				width: 3000px;
				height: 10px;
				content: ' ';
				background-color: $color-blue-1;
			}

			&::after {
				position: absolute;
				top: 0;
				right: -3000px;
				display: block;
				width: 3000px;
				height: 80px;
				content: ' ';
				visibility: visible;
				background-color: $color-blue-1;
			}
		}

		.header-design-bar-inner {
			width: 100%;
			height: 80px;
			overflow: hidden;
			background-image: url("../images/header_bar_union.svg");
			background-repeat: no-repeat;
			background-position: right top;
			background-size: cover;
		}
	}

	/* Header Outer */
	.header-design-outer {
		height: 810px;
		overflow: hidden;
		background-color: $color-blue-1;
		}

	/* Header Inner */
	.header-design-inner {
		position: relative;
		height: 670px;
		overflow: hidden;
		}

	.header-design-back {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 670px;
		background: rgb(0,0,0);
		background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,108,123,1) 100%);
		background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,108,123,1) 100%);
		background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,108,123,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#006c7b",GradientType=1);
		}

	.header-design-mid {
		position: absolute;
		top: 450px;
		left: 50%;
		display: block;
		width: 4559px;
		height: 1992px;
		visibility: visible;
		background: radial-gradient(21.21% 24.27% at 50% 0%, #01606C 0%, rgba(1, 96, 108, 0.2) 100%);

		@include rounded(50%);
		@include transform(translateX(-50%));
		}

	.header-design-fore {
		position: absolute;
		top: 500px;
		left: 50%;
		display: block;
		width: 3241px;
		height: 1416px;
		background-color: $color-blue-1;

		@include rounded(50%);
		@include transform(translateX(-50%));
		}
}