/* ************************************************************************************************ */
/* Buttons **************************************************************************************** */
/* ************************************************************************************************ */
main {

    /* Buttons */
    .btn,
    .button {
        position: relative;
        margin: 0 10px 0 0;
        padding: 24px 32px;
        display: inline-block;
        height: auto;
        color: $color-white;
        font-family: $font3;
        font-size: 0.90rem;
        font-weight: bold;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        overflow: hidden;
        border: 1px solid $color-blue-3;
        background-color: $color-blue-3;

        @include transition(all 0.20s ease-in-out);
        @include rounded(5px);
        @include boxshadow(0 0 0 rgba($color-white,0.00));

        &::-moz-focus-inner {
            padding: 0;
            border: 0 none;
            }

        /* Hover State */
        &:hover {
            color: white;
            border-color: $color-orange-1;
            background-color: $color-orange-1;
            }
    }
}