@import 'core/colors';
@import 'core/fonts';

@import 'core/variables';
@import 'core/mixins';
@import 'core/functions';

@import 'core/resets';
@import 'core/clearfix';
@import 'core/compliance';

@import 'core/typography';

@import 'core/animations';
@import 'core/buttons';
@import 'core/frame';
@import 'core/forms';
@import 'core/loaders';
@import 'core/notifications';
@import 'core/tables';

:root {
	--ting-yellow: #f0c415;
}

@import 'components/datagrid';
@import 'components/datamap';
@import 'components/footer';
@import 'components/header';
@import 'components/headerdesign';
@import 'components/infobox';
@import 'components/infomodal';
@import 'components/legend';
@import 'components/newsletter';

@import 'core/print';

#root {
	overflow-x: hidden;
}

svg.icon {
	height: 1.2em;
	vertical-align: middle;
}

#root {

	.btn-primary {
		--bs-btn-bg: white;
		--bs-btn-border-color: #E1E8F4;
		--bs-btn-color: #647590;
		--bs-btn-hover-color: var(--ting-yellow);
		--bs-btn-hover-bg: var(--ting-yellow);
		--bs-btn-hover-border-color: var(--ting-yellow);
		--bs-btn-active-bg: #647590;

		.icon {
			color: black;
		}
	}

	.btn {
		--bs-btn-padding-x: 14px;
		--bs-btn-padding-y: 14px;
		
		&:not(.btn-link) {
			box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.02), inset 0px 2px 0px rgba(255, 255, 255, 0.05);
		}

		&.btn-link {
			text-decoration: none;
		}
	}

}

#root .App.stat-thd {

	--thd-blue: #004E85;
	--thd-overlay-blue: rgba(0, 0, 0, 0.12);

	.btn-primary {
		--bs-btn-bg: var(--thd-blue);
		--bs-btn-border-color: var(--thd-overlay-blue);
		--bs-btn-active-bg: var(--bs-btn-hover-bg);

		.icon {
			color: white;
		}

		&:hover {
			.icon {
				color: black;
			}
		}
	}

	.btn {
		--bs-btn-color: white;
		color: var( --bs-btn-color );

		&:hover {
			--bs-btn-color: var( --ting-yellow);
		}
	}

	&::before {
		/* Rectangle 24 */
		position: absolute;
		width: 100%;
		height: 640px;
		left: 0px;
		top: 0px;
		content: '';

		background: radial-gradient(125.93% 100% at 47.85% 0%, #00487B 0%, #002540 100%), #002540;
	}

	
	.header-design .header-design-bar {

		&:before, &:after {
			background-color: var(--thd-overlay-blue);
		}

		.header-design-bar-inner {
			background-image: none;
			position: relative;
			
			&:before {
				background-image: url("data:image/svg+xml,%3Csvg width='1440' height='88' viewBox='0 0 1440 88' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1440 0H0V10H1027.36C1035.27 10 1042.67 13.9009 1047.15 20.4273L1076.08 62.6211C1083.54 73.4985 1095.88 80 1109.07 80H1440V0Z' fill='black' fill-opacity='0.12'/%3E%3C/svg%3E%0A");
				background-size: auto;
				background-position: top right;
	
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				height: 80px;
				width: 50%;
			}
	
			&:after {
				content: '';
				background-color: var(--thd-overlay-blue);
				top: 0;
				// left: -100%;
				right: 50%;
				height: 10px;
				position: absolute;
				width: 50vw;
				visibility: visible;
			}
		}
	}

	.header-logo span,
	ul.header-social-list > li.social-share-label {
		color: white;
	}

	.primary-nav ul,
	.type-toggle-nav ul {
		background-color: var(--thd-overlay-blue);
		
		a {
			color: white;

			&.active {
				background-color: var(--thd-blue);
			}
		}
	}

	.showing-by-utility {
		color: white;
		font-size: 20px;
	}

	.primary-nav ul li:hover a,
	.type-toggle-nav ul li:hover a {
		background-color: var(--thd-blue);
	}

	.data-map {
		.data-map-outer {
			& > div {
				background-image: url("data:image/svg+xml,%3Csvg width='680' height='88' viewBox='0 0 680 88' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_3693_175)'%3E%3Cpath d='M660 -4C660 -6.20914 658.209 -8 656 -8H24C21.7909 -8 20 -6.20914 20 -4C20 -1.79086 21.7909 0 24 0H193.38C202.98 0 211.657 5.72153 215.439 14.5459L226.971 41.4541C230.753 50.2785 239.43 56 249.031 56H430.969C440.57 56 449.247 50.2785 453.029 41.4541L464.561 14.5459C468.343 5.72152 477.02 0 486.62 0H656C658.209 0 660 -1.79086 660 -4Z' fill='%23013B65'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_3693_175' x='0' y='-16' width='680' height='104' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='12'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.211765 0 0 0 0 0.392157 0 0 0 0 0.52549 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3693_175'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3693_175' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
			
				&:before {
					background-image: url("data:image/svg+xml,%3Csvg width='240' height='52' viewBox='0 0 240 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.08105 21.3519C-3.42862 10.7021 4.30128 -2 16.2919 -2H223.708C235.699 -2 243.429 10.7021 237.919 21.3519L227.656 41.1899C224.221 47.8302 217.369 52 209.892 52H30.1078C22.6315 52 15.7795 47.8302 12.3442 41.1899L2.08105 21.3519Z' fill='url(%23paint0_linear_3560_6315)' fill-opacity='0.08'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_3560_6315' x1='116.5' y1='52' x2='116.5' y2='-2' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F2F5FA'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
				}
			}
		}

		.MuiInputBase-root .MuiSelect-select {
			color: white;
		}
	}

	.MuiPaper-root table.MuiTable-root thead tr th,
	.MuiPaper-root table.MuiTable-root thead tr td {
		width: 50% !important;
	}

	
}

.stat-thd .echarts-for-react > div:last-child span:last-of-type:after {
	content: '%';
}

.cpqi-thd-choice-modal {
	--bs-modal-header-border-width: 0;
	--bs-modal-padding: 0 40px 40px;

	color: #647590;

	.modal-header {
		background-image: url("data:image/svg+xml,%3Csvg width='1440' height='80' viewBox='0 0 1440 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1440 0H0V10H1321.86C1329.77 10 1337.17 13.9009 1341.65 20.4273L1370.58 62.6211C1378.04 73.4985 1390.38 80 1403.57 80H1440V0Z' fill='%23F2F5FA'/%3E%3C/svg%3E%0A");
		background-position: top right;
		background-repeat: no-repeat;
		background-size: auto 100%;

		height: 60px;
	}

	.modal-content {
		overflow-y: auto;
		overflow-x: hidden;
	}

	.modal-body {
		max-width: 1112px;
		margin: 0 auto;
		overflow-y: initial;

		.logo {
			width: 242px;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	.divider {
		margin: 80px 0 40px;
		position: relative;
		text-transform: uppercase;
		color: #647590;
		font-family: 'Rubik', sans-serif;
		overflow: visible;
		font-size: 18px;

		&:after {
			content: '';
			display: block;
			width: 100vw;
			height: 1px;
			background-color: #E1E8F4;

			position: absolute;
			top: 50%;
			left: 400px;
			transform: translateY(-50%);

			visibility: visible;
		}
	}

	h1 {
		font-size: 64px;
		font-weight: 600;
		letter-spacing: -1.92px;
		line-height: 1em;
		color: #403F41;
		font-family: 'Montserrat', sans-serif;
		margin-bottom: 20px;

		small {
			font-weight: 500;
			font-size: 24px;
			color: #647590;
			line-height: 2em;
			letter-spacing: -0.72px;
		}

		& + p {
			font-size: 18px;
		}
	}

	a.card {

		transition: .25s background-color;
		// overflow: hidden;

		--bs-card-border-width: 0;
		--bs-card-spacer-y: 24px;
		--bs-card-spacer-x: 32px;

		.card-body {
			border: 1px solid #E1E8F4;
			border-top-right-radius: var( --bs-card-inner-border-radius );
			border-top-left-radius: var( --bs-card-inner-border-radius );
			border-bottom-width: 0px;

			h2 {
				color: #403F41;
				font-size: 20px;
				font-family: 'Montserrat', sans-serif;
				line-height: 1.75em;
				margin-bottom: 0;
				font-weight: 600;
				letter-spacing: -0.6px;

				display: flex;
				align-content: center;
				align-items: center;
				justify-content: space-between;
				
				&:after {
					content: '';
					background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1L19 8M19 8L12 15M19 8L1 8' stroke='%23F4C400' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					width: 20px;
					height: 16px;
				}

				& + p {
					padding-right: 36px;
				}
			}

			p {
				color: #647590;
				font-size: 15px;
				line-height: 150%;
				font-family: "Rubik", sans-serif;
			}
		}

		&:hover {
			background-color: var( --ting-yellow );
			
			.card-body {
				border-color: var( --ting-yellow );
				h2 {
					&:after {
						background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1L19 8M19 8L12 15M19 8L1 8' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					}

					& + p {
						color: rgba(64, 63, 65, 0.80);
					}
				}
			}

			.card-img-bottom {
				transform: translateY(4px);
			}
		}

		.screen-image {
			padding: 16px 32px 0;
			border-top: 4px solid rgba(0,0,0,0.03);
			background-color: #DAE3F1;

			border-bottom-left-radius: var( --bs-card-inner-border-radius );
			border-bottom-right-radius: var( --bs-card-inner-border-radius );

			&.thd {
				background-color: #012946;
			}
		}

		.card-img-bottom {
			border-radius: 0;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;

			transform: translateY(12px);
			transition: .25s transform;

			height: 250px;
			object-fit: cover;
			object-position: top;
		}

		.screen-image {
			overflow: hidden;
		}
	}
}