/* ************************************************************************************************ */
/* Info Box *************************************************************************************** */
/* ************************************************************************************************ */
.infobox {
	position: relative;
	padding: 95px 40px 40px;
	height: 100%;
	background-color: $color-white;
	border: 1px solid #E1E8F4;

	@include rounded(16px);

	svg {
		position: absolute;
		top: 40px;
		left: 50%;
		display: block;
		width: 38px;
		color: $color-orange-1;

		@include transform(translateX(-50%));
		}

	h3 {
		text-align: center;
		}

	p {
		margin: 0 auto;
		display: block;
		width: 100%;
		max-width: 500px;
		text-align: center;

		a {
			color: $color-blue-2;
			text-decoration: underline;

			/* Hover State */
			&:hover {
				color: $color-orange-1;
				}
		}
	}
}