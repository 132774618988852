/* ************************************************************************************************ */
/* Data Map *************************************************************************************** */
/* ************************************************************************************************ */
.data-map {
  position: relative;
  z-index: 3;
  padding: 0 0 30px;

  .data-map-outer {
    position: relative;
    margin: 0 auto;
    padding: 0;
    height: 580px;
    background-color: #6197bd;

    @include rounded(16px);
    @include boxshadow(0 4px 8px rgba(1, 38, 65, 0.12));

	& > div {
		background-image: url("data:image/svg+xml,%0A%3Csvg width='680' height='88' viewBox='0 0 680 88' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_2782_2859)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M660 -4C660 -6.20914 658.209 -8 656 -8H24C21.7909 -8 20 -6.20914 20 -4C20 -1.79086 21.7909 0 24 0H193.38C202.98 0 211.657 5.72153 215.439 14.5459L226.971 41.4541C230.753 50.2785 239.43 56 249.031 56H430.969C440.57 56 449.247 50.2785 453.029 41.4541L464.561 14.5459C468.343 5.72152 477.02 0 486.62 0H656C658.209 0 660 -1.79086 660 -4Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_2782_2859' x='0' y='-16' width='680' height='104' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='12'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.211765 0 0 0 0 0.392157 0 0 0 0 0.52549 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2782_2859'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_2782_2859' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
		background-position: top center;
		background-repeat: no-repeat;

		position: relative;

		&:before {
			background-image: url("data:image/svg+xml,%0A%3Csvg width='240' height='52' viewBox='0 0 240 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.08105 21.3519C-3.42862 10.7021 4.30128 -2 16.2919 -2H223.708C235.699 -2 243.429 10.7021 237.919 21.3519L227.656 41.1899C224.221 47.8302 217.369 52 209.892 52H30.1078C22.6315 52 15.7795 47.8302 12.3442 41.1899L2.08105 21.3519Z' fill='url(%23paint0_linear_2782_2862)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2782_2862' x1='116.5' y1='52' x2='116.5' y2='-2' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F2F5FA'/%3E%3Cstop offset='1' stop-color='white' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			
			content: '';
			position: absolute;
			width: 240px;
			left: 50%;
			transform: translate(-50%, 0);
			height: 54px;
			top: 0px;
			visibility: visible;
		}
	}
  }
  .data-map-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .data-map-inner {
    position: relative;
  }

  .MuiInputBase-root {
    @include rounded(0);

    .MuiOutlinedInput-notchedOutline {
      border-width: 0;

      legend {
      }
    }

    svg {
      position: absolute;
      right: 10px;
	  z-index: 1;
    }

    .MuiSelect-select {
      color: #647590;
      font-size: 1.125rem;
      outline: 0 none;
	  position: relative;
	  z-index: 2;
		padding-left: 40px;
		padding-right: 40px !important;

      @include rounded(0);

      &:focus,
      &:hover {
        outline: 0 none;
      }

      &.MuiSelect-outlined {
      }
      &.MuiInputBase-input {
      }
      &.MuiOutlinedInput-input {
      }
    }
  }
}

.leaflet-control-zoom {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #f0f0f0 !important;
  padding: 6px;
  margin-left: 24px !important;
  margin-bottom: 24px !important;
}

.leaflet-control-zoom a {
	color: $color-text;
	font-size: 18px !important;
}