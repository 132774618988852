/* ************************************************************************************************ */
/* Frame ****************************************************************************************** */
/* ************************************************************************************************ */
#root {

	.App {

		.app-frame-outer {
			padding-right: 15px;
			padding-left: 15px;
			background-color: $color-white;

			.app-frame-inner {
				position: relative;
				z-index: 3;
				margin: 0 auto;
				max-width: $maxwidth;
				}
		}

		main {
			position: relative;
			z-index: 3;
			padding: 0 0 65px;
			background-color: $color-white;

			.main-outer {
				margin: 0 auto;
				}
			.main-inner {}
		}
	}
}