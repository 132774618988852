/* ************************************************************************************************ */
/* Forms ****************************************************************************************** */
/* ************************************************************************************************ */
main {

    form {
        margin: 0;
        padding: 0;
        }

    .form-group {
        padding: 0 0 30px;
        }

    label {
        margin: 0;
        padding: 0 0 10px;
        display: block;
        font-size: 1.10rem;
        font-weight: bold;
        font-weight: 700;
        }

    /* Fields */
    input.text,
    input[type="date"],
    input[type="email"],
    input[type="password"],
    input[type="phone"],
    input[type="tel"],
    input[type="text"],
    select,
    textarea {
        margin: 0;
        padding: 14px 16px;
        display: block;
        width: 100%;
        color: $color-blue-4;
        font-family: $font3;
        font-size: 0.95rem;
        line-height: 1;
        border-width: 0;
        background-color: $color-blue-1;

		&.is-invalid {
			border-width: 1px;
		}

        @include rounded(8px);
        @include boxshadow(inset 1px 1px 4px rgba(0, 0, 0, 0.04));
        @include transition(border-color 0.10s ease-in-out);

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $color-blue-4;
            text-transform: uppercase;
            }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $color-blue-4;
            text-transform: uppercase;
            }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $color-blue-4;
            text-transform: uppercase;
            }
        &:-moz-placeholder { /* Firefox 18- */
            color: $color-blue-4;
            text-transform: uppercase;
            }

        /* Hover State */
        &:hover {
            border-color: $color-orange-1;
            }

        /* Focus State */
        &:focus {
            border-color: $color-orange-1;
            }
    }

    textarea {
        height: 200px;
        line-height: 1.35rem;
        }

    ul {
        &.checkbox-list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            > li {
                margin: 3px 0;
                display: block;
                }
        }
    }

    label {
        &.custom-checkbox {
            position: relative;
            margin: 0;
            padding: 0 0 0 35px;
            font-weight: normal;
            font-weight: 400;
            cursor: pointer;

            input {
                &[type="checkbox"] {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    display: block;
                    width: 100%;
                    height: 100%;
                    visibility: hidden;

                    @include opacity(0.000001);

                    + span {
                        display: block;

                        &::before {
                            position: absolute;
                            top: 1px;
                            left: 0;
                            display: block;
                            width: 24px;
                            height: 24px;
                            color: $color-gray-3;
                            font-family: $faregular;
                            font-size: 1.35rem;
                            line-height: 24px;
                            text-align: center;

                            /* https://fontawesome.com/icons/square?s=regular&f=classic */
                            content: '\f0c8';

                            @include transition(color 0.10s ease-in-out);
                            }
                    }

                    /* Hover State */
                    &:hover {
                        + span {
                            &::before {
                                color: $color-orange-1;
                                }
                        }
                    }

                    &:checked {
                        + span {
                            &::before {
                                color: $color-orange-1;
                                font-family: $fasolid;

                                /* https://fontawesome.com/icons/square-check?s=solid&f=classic */
                                content: '\f14a';
                                }
                        }
                    }
                }
            }
        }
    }
}

/* Media Queries */