/* ************************************************************************************************ */
/* Typography ************************************************************************************* */
/* ************************************************************************************************ */
main {
	a {
		color: $color-blue-2;

		/* Hover State */
		&:hover {
			color: $color-orange-1;
			}
	}

	h1 {
		position: relative;
		padding: 10px 0 20px;
		font-size: 2.75rem;
	    line-height: 3.50rem;
		}

	h2 {
		position: relative;
		margin: 0 0 25px;
		padding: 0 0 25px;
	    font-size: 2.15rem;
	    line-height: 3.00rem;
		}

	h3 {
		color: #2a4879;
		font-family: $font4;
		font-size: 1.45rem;
		font-weight: 700;
	    line-height: 2.05rem;
		text-transform: uppercase;
		}

	h4 {
		font-size: 1.65rem;
	    line-height: 2.00rem;
		}

	h5 {
		font-size: 1.05rem;
		line-height: 1.55rem;
		}

	h6 {
		padding: 10px 0;
		font-size: 0.95rem;
		font-weight: bold;
		font-weight: 600;
		text-transform: uppercase;
		}

	p {
	    padding: 15px 0;
		color: $color-blue-4;
	    font-size: 1.15rem;
	    line-height: 1.55rem;
		}

	sup {
		position: relative;
		top: -0.65rem;
		font-size: 50%;
		}

	code {}

	address {}

	blockquote {
	    position: relative;
	    margin: 25px 0;
		padding: 30px 0 0 155px;

	    p {
		    font-style: italic;

		    &:first-child { padding-top: 0; }
		    &:last-child { padding-bottom: 0; }
	    }

	    &::before {
		    position: absolute;
		    top: 0;
		    left: 50px;
		    display: block;
		    width: 73px;
		    height: 73px;
		    color: $color-blue-1;
		    font-family: $fasolid;
		    font-size: 4.50rem;
		    line-height: 73px;
		    text-align: center;

		    /* https://fontawesome.com/v5.15/icons/quote-left?style=solid */
		    content: '\f10d';
		    }
	}

	li {
		margin: 0;
		padding: 4px 0;
	    font-size: 0.85rem;
	    font-weight: normal;
	    font-weight: 400;
	    line-height: 1.55rem;
		}

	ul {
		margin: 10px 0 10px 30px;
		padding: 0;
	    list-style-type: disc;

		li {
			padding-left: 5px;
			}

		> li {

			> ul {
				margin-top: 4px;
				margin-bottom: 0;
				list-style-type: circle;

				> li {

					> ul {
						margin-top: 4px;
						margin-bottom: 0;
						list-style-type: square;

						> li {}
					}
				}
			}
		}
	}
}

ol {
	margin: 10px 0 10px 30px;
    padding: 0;
    list-style-type: decimal;

	li {
		padding-left: 5px;
		}

	> li {

		> ol {
			margin-top: 4px;
			margin-bottom: 0;
			list-style-type: lower-alpha;

			> li {

				> ol {
					margin-top: 4px;
					margin-bottom: 0;
					list-style-type: lower-alpha;

					> li {}
				}
			}
		}
	}
}

/* Media Queries */
/* 800px */
@media screen and (max-width: 800px){

}


/* Font Weights */
.weight-100	{ font-weight: normal !important; font-weight: 100 !important; }
.weight-200	{ font-weight: normal !important; font-weight: 200 !important; }
.weight-300	{ font-weight: normal !important; font-weight: 300 !important; }
.weight-400	{ font-weight: normal !important; font-weight: 400 !important; }
.weight-500	{ font-weight:   bold !important; font-weight: 500 !important; }
.weight-600	{ font-weight:   bold !important; font-weight: 600 !important; }
.weight-700	{ font-weight:   bold !important; font-weight: 700 !important; } /* Should use <b> or <strong> */
.weight-800	{ font-weight:   bold !important; font-weight: 800 !important; }
.weight-900	{ font-weight:   bold !important; font-weight: 900 !important; }


/* Text Align */
.align-left 	{ text-align: left !important; }
.align-center 	{ text-align: center !important; }
.align-right 	{ text-align: right !important; }
.align-justify 	{ text-align: justify !important; }


/* Font Sizes */
/**
 * Generates Font size Classes
 * Generates class values in increments of 1, from 0 to 100.
 * Margin E.g. .fontsize-1px, .fontsize-10px, etc.
 */
@each $space in $spaceamounts {
	.fontsize-#{$space}px { font-size: #{$space}px !important; }
}


/**
 * Generates Line Height Classes
 * Generates class values in increments of 1, from 0 to 100.
 * Margin E.g. .lineheight-1px, .lineheight-10px, etc.
 */
@each $space in $spaceamounts {
	.lineheight-#{$space}px { line-height: #{$space}px !important; }
}


/**
 * Generates Margin and Padding Classes
 * Generates class values in increments of 1, from 0 to 100.
 * Margin E.g. .margin-all-0, .margin-all-20, .margin-top-10, .margin-left-100, etc.
 * Padding E.g. .padding-all-0, .padding-all-20, .padding-top-10, .padding-left-100, etc.
 */
@each $space in $spaceamounts {
	@each $side in $spacesides {
		@if $side == all {
			//.margin-#{str-slice($side, 0, 1)}-#{$space} {
			.margin-#{$side}-#{$space} {
				margin: #{$space}px !important;
			}
			//.padding-#{str-slice($side, 0, 1)}-#{$space} {
			.padding-#{$side}-#{$space} {
				padding: #{$space}px !important;
			}
		} @else {
			//.margin-#{str-slice($side, 0, 1)}-#{$space} {
			.margin-#{$side}-#{$space} {
				margin-#{$side}: #{$space}px !important;
			}
			//.padding-#{str-slice($side, 0, 1)}-#{$space} {
			.padding-#{$side}-#{$space} {
				padding-#{$side}: #{$space}px !important;
			}
		}

	}
}
