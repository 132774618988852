/* ************************************************************************************************ */
/* General Styles ********************************************************************************* */
/* ************************************************************************************************ */
html, body {
	width: 100%;
	height: 100%;
	}
	body {
		margin: 0;
		padding: 0;
        color: $color-black;
		font-family: $font3;
        font-size: $global-font-size;
        line-height: 1;
		overflow-x: hidden;
		background-color: $color-white;

		text-rendering: optimizeLegibility;
		text-rendering: geometricPrecision;
		font-smooth: always;
		font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		
		-webkit-font-smoothing: antialiased;
		-webkit-font-smoothing: subpixel-antialiased;
		/*
		-webkit-text-stroke: 0.3px;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
		*/
	    }

div, span, applet, object, iframe, h1, h2, h3, h4,
h5, h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas,
details, embed, figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark,
audio, video {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	border: 0;
	}
html, html a {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow:none;
    text-rendering: optimizeLegibility;
	}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
    }

ul, ol {
	list-style: none;
	}

blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
	}

::-moz-selection,
::selection {
	color: $color-white;
	background: $color-blue-2;
	
	text-shadow: none;
	}

h1,h2,h3,h4,h5,h6,
ul,ol,li,dd,dt,dl, /*div,span,*/
blockquote,p,blockquote,q,
aside,main,column,header,footer,
article,menu,nav,sub,sup {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-weight: 400;
    }

a {
	color: $color-blue-2;
	text-decoration: none;
	
	@include transition(color 0.10s ease-in-out);

	&:visited {}
	&:hover {
		color: $color-blue-2;
		}
	&:focus {
		outline: auto;
		}
}


img {
	border: 0 none;
	
    /* Assures that the the maximum width of the image will not be bigger than the size of the containing screen */
    height: auto;
    max-width: 100%;
	}

sup,
sub {
	margin: 0;
	padding: 0;
	font-size: 10px;
	line-height: 0;
	}
abbr[title] {
	border-bottom: 1px dotted;
	}

b, strong {
	font-weight: bold;
	font-weight: 700;
	}

blockquote {
	margin: 1em 40px;
	}

dfn {
	font-style: italic;
	}

hr {
	clear: both;
	margin: 20px 0 40px;
    width: 100%;
    height: 0;
    border: solid $color-blue-2;
    border-color: rgba($color-blue-2,0.30);
    border-width: 1px 0 0;
	}

ins {
	color: $color-black;
	text-decoration: none;
	background: $color-blue-2;
	}

mark {
	color: $color-black;
	font-style: italic;
	font-weight: bold;
	background: $color-blue-2;
	}

/* Redeclare monospace font family: h5bp.com/j */
pre, code, kbd, samp {
	font-family: monospace, monospace;
	_font-family: 'courier new', monospace;
	font-size: 1em;
	}

/* Improve readability of pre-formatted text in all browsers */
pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
	}

#dd {
    float: right;
	
	i {
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: 100%;
        text-align: center;
        background: $color-gray-1;
		
		&::before {
            position: absolute;
            top: 15px;
            left: 12px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 7px 0 6px;
            border-color: $color-white transparent transparent transparent;
            content: "";
			}
	}
}

/* Mozilla Fix */
button,
input {
	&::-moz-focus-inner {
		padding: 0;
		border: 0 none;
		}
}

/* Global Box Sizing */
*,
*:before,
*:after {
	@include boxsizing(border-box);
	}
